<template>
  <div class="container">
    Connected Admins:
    <span v-for="admin in connections" :key="admin">
      {{ admin }}
    </span>
  </div>
</template>
<script>
export default {
  name: "allAdmins",
  computed: {
    admins() {
      return this.$store.getters["admins/admins"];
    },
    connections() {
      return this.$store.getters["admins/connections"];
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  text-align: center;
  display: flex;
  flex-direction: row;
  font-family: "Times New Roman", Times, serif;
  font-size: 1.1rem;
  font-weight: 600;
  justify-content: center;
  span {
    margin: 0 5px;
    text-decoration: underline;
  }
}
</style>
