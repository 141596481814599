<template>
  <div>
    <div class="container">
      <form @submit.prevent="submit">
        <div class="form-row">
          <label>App Name: </label>
          <input v-model="search" type="search" placeholder="Search.." />
        </div>
      </form>
    </div>
    <AllAppsComp :search="search" />
  </div>
</template>
<script>
import AllAppsComp from "../components/AllAppsComp.vue";

export default {
  name: "HomeMain",
  components: {
    AllAppsComp,
  },
  data: () => ({
    search: "",
  }),

  computed: {
    searchApps() {
      if (!this.search) return [];
      return this.$store.getters["apps/appsSearch"](this.search);
    },
  },
  methods: {
    submit() {
      this.search = "";
    },
  },
};
</script>
<style lang="scss" scoped>
form {
  display: flex;
  justify-content: center;
  div {
    max-width: 460px;
  }
}
</style>
