<template>
  <div class="home">
    <Admins />
    <HomeMain />
  </div>
</template>

<script>
// @ is an alias to /src
import HomeMain from "@/components/HomeMain.vue";
import Admins from "@/components/Admins.vue";

export default {
  name: "Home",
  components: {
    HomeMain,
    Admins,
  },
  mounted() {
    console.log("Home Mounted");
    // console.log(this.$store);
  },
  // computed: {
  //   locale() {
  //     return this.$store.getters["general/locale"];
  //   },
  // },
  // watch: {
  //   locale(locale, oldLocale) {
  //     console.log("locale: " + locale, "old: " + oldLocale);
  //   },
  // },
};
</script>
