<template>
  <div>
    <div class="container">
      <table>
        <thead>
          <tr>
            <th>App-Name</th>
            <th>Server-Host</th>
            <th>Client-Host</th>
            <th>Auth-Path</th>
            <th>Users-Connected</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="app in searchApps" :key="app.name">
            <td
              style="cursor: pointer"
              @click="$router.push(`/app/${app.name}`)"
            >
              {{ app.name }}
            </td>
            <td>{{ app.server_host }}</td>
            <td>{{ app.client_host }}</td>
            <td>{{ app.auth_path }}</td>
            <td>{{ app.connections }}</td>
            <td>
              <button @click="$router.push(`/app/${app.name}/edit`)">
                Edit
              </button>
              <button @click="$router.push(`/app/${app.name}/delete`)">
                Remove
              </button>
            </td>
          </tr>
          <tr v-show="!searchApps.length">
            <td colspan="6">No apps found...</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "AllAppsComp",
  props: {
    search: { type: String, default: "" },
  },
  computed: {
    searchApps() {
      let apps;
      if (!this.search) apps = this.$store.getters["apps/apps"];
      else apps = this.$store.getters["apps/appsSearch"](this.search);
      const copiedApps = JSON.parse(JSON.stringify(apps));
      return Object.values(copiedApps).map((app) => {
        const namespace = "/" + app.name;
        app.connections =
          this.$store.getters["appDetails/appConnections"](namespace);
        return app;
      });
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.container {
  margin: 20px;
  display: flex;
  justify-content: center;
}

button {
  font-size: small;
  padding: 5px;
  margin: 3px;
}
</style>
